<script setup lang="ts">
const { count: wishlistCount } = useWishlist();
const { isLoggedIn, logout, user, refreshUser } = useUser();

const GROUP_CONTENT_EDITOR = "0192d2724974784bb2cbb1181feb9d38";

const contentEditorAllowed = asyncComputed(async () => {
  if (!isLoggedIn.value) return false;
  if (import.meta.dev) return true;

  if (!user.value?.group.id) await refreshUser({ associations: { group: {} } });
  const shouldShowInternal = user.value?.group?.id == GROUP_CONTENT_EDITOR;
  return shouldShowInternal;
}, false);

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();
const { controller } = toRefs(props);
const { toggle, close, isOpen } = controller.value;

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

async function invokeLogout() {
  logout();
  navigateTo("/");
}

const wishlistRoute = computed(() =>
  isLoggedIn.value ? "/account/wishlist" : "/wishlist",
);

onMounted(() => {
  const body = document.body;
  body.classList.remove("overflow-hidden");

  watch(isOpen, (open) => body.classList.toggle("overflow-hidden", open));
});
</script>

<template>
  <div
    tabindex="0"
    class="size-10 xl:size-12.5 cursor-pointer"
    :class="{ 'custom-underline': isOpen }"
    @click="toggle"
  >
    <div class="h-full mx-auto w-5 xl:w-6 i-sl-single-neutral-alternate"></div>
  </div>
  <LayoutSidebar
    :controller="controller"
    side="right"
    layer-class="z-1500 xl:z-10"
    class="bg-scheppach-primary-50! flex text-left"
  >
    <div
      class="xl:mb-11 xl:mt-22.5 px-5 py-6.5 xl:p-10 text-left xl:bg-[#336d9d] flex text-scheppach-primary-700 xl:text-scheppach-shades-0 items-center"
    >
      <div class="text-5 xl:text-6 font-700 uppercase flex-grow">
        {{ $t("account.menu.header") }}
      </div>
      <button
        type="button"
        class="size-4 xl:size-5 i-sl-close cursor-pointer"
        @click="close"
      ></button>
    </div>
    <div class="flex-grow px-5 xl:px-10">
      <div class="font-700 text-5 xl:text-lg mb-5 xl:mb-4">
        <template v-if="isLoggedIn">
          {{ $t("account.menu.hello") }}, {{ user?.firstName }}
          {{ user?.lastName }}
        </template>
        <NuxtLink v-else :to="formatLink('/account/login')">
          <button
            type="button"
            class="w-full text-left bg-transparent hover:c-scheppach-primary-500 hover:underline underline-offset-4"
          >
            {{ $t("account.signIn") }}
          </button>
        </NuxtLink>
      </div>
      <div
        class="text-5 xl:text-lg flex flex-grow flex-col gap-5 xl:gap-4 p-0 m-0"
      >
        <NuxtLink
          v-if="isLoggedIn && contentEditorAllowed"
          class="block c-scheppach-success-500 hover:c-scheppach-success-500 hover:underline underline-offset-4"
          :to="formatLink('/member/promotion-editor')"
        >
          {{ $t("account.contentEditor") }}
        </NuxtLink>

        <NuxtLink
          v-if="isLoggedIn"
          class="block hover:c-scheppach-primary-500 hover:underline underline-offset-4"
          :to="formatLink('/account')"
        >
          {{ $t("account.menu.myAccount") }}
        </NuxtLink>
        <NuxtLink
          class="block hover:c-scheppach-primary-500 hover:underline underline-offset-4"
          :to="formatLink(wishlistRoute)"
        >
          {{ $t("account.menu.wishlist") }}
          <span v-if="wishlistCount">({{ wishlistCount }})</span>
        </NuxtLink>
        <!-- <NuxtLink
          class="block hover:c-scheppach-primary-500 hover:underline underline-offset-4"
          :to="formatLink('/gift-card')"
        >
          {{ $t("giftCard.giftCards") }}
        </NuxtLink> -->

        <button
          v-if="isLoggedIn"
          class="block flex justify-between items-center bg-transparent capitalize mt-5 hover:c-scheppach-error-500 hover:underline underline-offset-4"
          @click="invokeLogout"
        >
          {{ $t("account.menu.signOut") }}
          <div class="i-sl-logout-1-alternate size-4"></div>
        </button>
      </div>
    </div>
    <div
      class="xl:hidden bg-scheppach-primary-500 p-5 pt-4 rd-tl-2.5 text-left text-lg absolute bottom-0 left-0 right-0 text-scheppach-primary-50 flex flex-col gap-2.5"
    >
      <h4 class="text-5">{{ $t("footer.any-questions") }}</h4>
      <div class="text-sm">
        <span>{{ $t("footer.phone") }}: </span>
        <a class="custom-underline" href="tel:+49822340020">08223/40020</a>
      </div>
      <div class="text-sm">
        <span>{{ $t("footer.email") }}: </span>
        <a class="custom-underline" href="maito:info@scheppach.com">
          info@scheppach.com
        </a>
      </div>
      <div class="mt-2.5">
        <NuxtLink
          :to="formatLink('/contact')"
          class="text-scheppach-primary-500 bg-scheppach-shades-0 b-scheppach-primary-500 b rd py-2 px-5 text-sm uppercase"
        >
          {{ $t("footer.contact") }}
        </NuxtLink>
      </div>
    </div>
  </LayoutSidebar>
</template>
